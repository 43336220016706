<template>
    <section class="container">
        <stockTopMenu curPosition="时段目标跟进" windowId="timeTargetFollow" module="timeQuantum"></stockTopMenu>
        <section
            v-loading="searchLoading"
            element-loading-text="请稍后，全力加载中..."
            element-loading-spinner="el-icon-loading"
        >
            <section class="row align-center space-between one-row">
                <div class="row align-center">
                    <p class="row align-center text-p">
                        <span class="row align-center space-center">1</span>
                        <i>分析每天业绩的重点时段</i>
                    </p>
                    <p class="row align-center text-p">
                        <span class="row align-center space-center">2</span>
                        <i>关注与昨天同时段的差距</i>
                    </p>
                    <p class="row align-center text-p">
                        <span class="row align-center space-center">3</span>
                        <i>不断加强自己对目标的关注</i>
                    </p>
                </div>
            </section>
            <div class="row align-center space-between">
                <section class="tip-row">
                    <i class="el-icon-message-solid"></i>每天定时截图发送至钉钉&lt;店铺钉钉群&gt;，延时不得超过30分钟
                </section>
                <div class="row align-center date-row">
                    <span>日期：</span>
                    <el-date-picker
                        v-model="date"
                        style="width: 150px"
                        size="small"
                        type="date"
                        placeholder="选择日期"
                        value-format="yyyyMMdd"
                        @change="fetch"
                    >
                    </el-date-picker>
                    <span class="save-span" @click="saveHandel">保存</span>
                </div>
            </div>
            <main class="main">
                <NoDataState v-if="!contentList.length" marginTop="220" text-desc="未查询到业绩数据"></NoDataState>
                <table v-else border>
                    <tr v-for="(header, idx) in headerList" :key="idx">
                        <td class="dept-name" rowspan="2" colspan="2" v-if="idx === 0">{{ deptCode }}</td>
                        <td class="header-cell" v-for="(cell, c) in header.list" :key="c">
                            {{ idx === 0 ? cell : cell.substring(5, 12) }}
                        </td>
                    </tr>
                    <template v-for="(body, index) in contentList">
                        <tr :key="body.time">
                            <td rowspan="2" class="time-cell">{{ body.time }}</td>
                            <td class="target-cell">{{ body.target }}</td>
                            <td class="target" v-for="(target, t) in body.targetList" :key="t">
                                {{ target.salesTotal }}
                            </td>
                        </tr>
                        <tr :key="body.time + index">
                            <td class="red">{{ body.sales }}</td>
                            <td v-for="(sales, s) in body.salesList" :key="s" @click="changeTip(s)">
                                <input
                                    class="input red"
                                    type="text"
                                    v-model="sales.salesTotal"
                                    @change="inputHandel(s, index + '', sales.salesTotal)"
                                    @keyup.enter="$event.target.blur()"
                                    :disabled="todayIndex !== s"
                                />
                            </td>
                        </tr>
                    </template>
                    <tr v-for="(item, i) in totalList" :key="item.title">
                        <td
                            colspan="2"
                            :class="[
                                i === 0 ? 'green' : i === 1 || i === 2 ? 'orange' : i === 3 ? 'gray' : 'lightBlue'
                            ]"
                        >
                            {{ item.title }}
                        </td>
                        <td
                            :class="[
                                i === 0 ? 'green' : i === 1 || i === 2 ? 'orange' : i === 3 ? 'gray' : 'lightBlue'
                            ]"
                            v-for="(cell, f) in item.list"
                            :key="f"
                        >
                            <input
                                class="input footer-input red"
                                type="text"
                                v-if="i === 3"
                                v-model="cell.salesTotal"
                                @change="inputHandel(f, '4', cell.salesTotal)"
                                @keyup.enter="$event.target.blur()"
                                disabled
                            />
<!--							:disabled="todayIndex !== f"-->
                            <span v-else :class="[i === 4 ? 'red' : '']">{{ cell.salesTotal }}</span>
                        </td>
                    </tr>
                </table>
            </main>
        </section>
    </section>
</template>

<script>
import moment from 'moment'
import stockTopMenu from '@/components/stockTopMenu.vue'
import NoDataState from '@/components/noDataState'
import { queryPeriodFollow, updatePeriodFollow } from '@/libs/http/api'
export default {
    name: 'timeTargetFollow',
    components: {
        stockTopMenu,
        NoDataState
    },
    data() {
        return {
            deptName: this.$cache.local.get('deptName'),
            deptCode: this.$cache.local.get('deptCode'),
            searchLoading: false,
            saveLoading: false,
            date: moment().format('yyyyMMDD'),
            today: moment().format('yyyy年MM月DD日'),
            todayIndex: '',
            headerList: [],
            contentList: [],
            totalList: [],
            saveList: []
        }
    },
    created() {
        this.fetch()
    },
    methods: {
        fetch(e) {
            if (e) {
                this.date = e
            }
            this.searchLoading = true
            queryPeriodFollow({
                forDate: this.date,
                deptId: this.$cache.local.get('deptId')
            })
                .then((data) => {
                    this.searchLoading = false
                    this.headerList = data.headerList || []
                    this.contentList = data.contentList || []
                    this.totalList = data.totalList || []
                    this.todayIndex = this.headerList[1].list.findIndex((item) => {
                        return item === this.today
                    })
                })
                .catch((error) => {
                    this.searchLoading = false
                    this.$message({ type: 'error', message: error, duration: 2000, showClose: true })
                })
        },
        changeTip(s) {
            if (s !== this.todayIndex)
                return this.$message({ type: 'error', message: '只能修改当天的业绩', duration: 2000, showClose: true })
        },
        inputHandel(s, type, value) {
            const date = this.headerList[1].list[s]
            const forDate = date.substring(0, 4) + date.substring(5, 7) + date.substring(8, 10)
            let saveObj = {
                type: type,
                value: value,
                forDate: forDate
            }
            let idx = this.saveList.findIndex((item) => {
                return item.type === saveObj.type && item.forDate === saveObj.forDate && item.value !== saveObj.value
            })
            if (idx !== -1) {
                this.saveList.splice(idx, 1, saveObj)
            }
            let index = this.saveList.findIndex((item) => {
                return item.type === saveObj.type && item.forDate === saveObj.forDate && item.value === saveObj.value
            })
            if (index === -1) {
                this.saveList.push(saveObj)
            }
            this.saveHandel()
        },
        saveHandel() {
            if (!this.saveList.length)
                return this.$message({ type: 'error', message: '请修改业绩后再保存', duration: 2000, showClose: true })
            if (this.saveLoading) return
            this.saveLoading = true
            this.searchLoading = true
            updatePeriodFollow({
                list: this.saveList,
                deptId: this.$cache.local.get('deptId')
            })
                .then((data) => {
                    this.saveList = []
                    this.saveLoading = false
                    this.fetch()
                    this.$message({ type: 'success', message: '操作成功', duration: 2000, showClose: true })
                })
                .catch((error) => {
                    this.saveLoading = false
                    this.searchLoading = false
                    this.$message({ type: 'error', message: error, duration: 2000, showClose: true })
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    .tip-row,
    .main {
        font-size: 20px;
    }
}
.one-row {
    background: #fff;
    margin: 10px;
    padding: 0 10px;
    .text-p {
        font-size: 20px;
        line-height: 60px;
        margin-right: 30px;
        span {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: rgb(230, 247, 255);
            font-weight: 900;
            margin-right: 2px;
            font-size: 30px;
        }
    }
}
.date-row {
    border-radius: 4px;
    margin: 10px;
    span {
        width: 50px;
    }
    .save-span {
        background: #0b83f3;
        color: #fff;
        height: 31px;
        line-height: 31px;
        border-radius: 4px;
        width: 55px;
        text-align: center;
        margin-left: 10px;
        cursor: pointer;
    }
}
.tip-row {
    margin: 10px;
    background: rgb(254, 249, 239);
    padding: 4px 10px 4px 0;
    i {
        color: rgb(248, 71, 95);
        margin: 0 10px;
    }
}

.main {
    margin: 0 10px;
    height: calc(100vh - 200px);
    background: #fff;
    padding: 10px;
    table {
        border-collapse: collapse;
        border: 1px solid #bbb;
        font-weight: bold;
        td {
            width: calc(100% / 9);
            height: calc((100vh - 210px) / 15.2);
            text-align: center;
            color: #000;
        }
        .dept-name {
            background-color: #ffff00;
            font-size: 32px;
        }
        .header-cell {
            background-color: #ffff00;
        }
        .time-cell {
            background-color: #d8d8d8;
        }
        .target-cell {
            background-color: #e2f0d9;
        }
        .target {
            background-color: #e2f0d9;
        }
        .input {
            background: none;
            outline: none;
            border: none;
            text-align: center;
            font-size: 20px;
            font-weight: bold;
            width: 100%;
            height: 100%;
            &.footer-input {
                background-color: #ffff00;
            }
        }
        .green {
            background-color: #92d14f;
        }
        .orange {
            background-color: #f7caab;
        }
        .gray {
            background-color: #d8d8d8;
        }
        .lightBlue {
            background-color: #a1f4ec;
            &:not(:first-child) {
                background-color: #ffffff;
            }
        }
        .red {
            color: #f8475f;
        }
    }
}
</style>
